import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from '@/services/TranslationService';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import ActiveSelectedApplication from './ActiveSelectedApplication/ActiveSelectedApplication';
import ApplicationAvailabilitySelection from './ApplicationAvailabilitySelection';
import { APPLICATIONS_SELECTIONS } from './applications-constants';

const Applications = (props) => {
  const { setHeaderTitle } = props;

  const [selectedSelection, setSelectedSelection] = useState(
    Object.keys(APPLICATIONS_SELECTIONS)[0]
  );

  const [activeApplication, setActiveApplication] = useState(null);

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.Applications'));
  }, []);

  const resetActiveApplication = () => {
    setActiveApplication(null);
  };

  const onApplicationSubmitted = () => {
    resetActiveApplication();
    setSelectedSelection('submitted');
  };

  return (
    <div className="flex flex-1 flex-col relative">
      <div className="flex flex-1 flex-col sm:m-10 m-0 sm:max-h-[calc(100vh-10rem)] py-2 sm:px-8 px-0 h-fit  rounded-sm">
        {!activeApplication && (
          <>
            <div className="md:mx-0 mx-auto mb-5 ">
              <ApplicationAvailabilitySelection
                options={APPLICATIONS_SELECTIONS}
                selected={selectedSelection}
                setSelected={setSelectedSelection}
              />
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              {React.createElement(
                APPLICATIONS_SELECTIONS[selectedSelection].component,
                {
                  setActiveApplication,
                }
              )}
            </div>
          </>
        )}
        {activeApplication && (
          <ActiveSelectedApplication
            activeApplication={activeApplication}
            onResetActiveApplication={resetActiveApplication}
            onApplicationSubmitted={onApplicationSubmitted}
          />
        )}
      </div>
    </div>
  );
};

export default withNOVOHeader(withRouter(Applications));
