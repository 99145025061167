import { firstUpperCaseLetter } from '@/lib/utils';
import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

dayjs.extend(utc);
dayjs.extend(timezone);

const SubmittedApplicationsItem = ({ application }) => {
  const { title, created_at, status, status_color } = application;

  const createdAt = dayjs(created_at.date)
    .tz(created_at.timezone)
    .format('DD/MM/YYYY');

  const statusValues = Object.values(status)[0];
  const statusColorValues = Object.values(status_color)[0];

  return (
    <div className="flex flex-row justify-between items-center w-full bg-white p-4 mt-4 shadow-sm">
      <div className="flex flex-col space-y-2">
        <Typography variant="bold_16">{title}</Typography>
        <Typography variant={'regular_12'} className="text-gray-400">
          {`${translate('Submitted at')} ${createdAt}`}
        </Typography>
      </div>
      <div
        class={`inline-block rounded-full px-4 py-2 text-center`}
        style={{ backgroundColor: statusColorValues }}
      >
        <Typography variant="regular_12">
          {firstUpperCaseLetter(translate(String(statusValues).toLowerCase()))}
        </Typography>
      </div>
    </div>
  );
};

export default SubmittedApplicationsItem;
