import React from 'react';

const ApplicationFormIcons = ({ className }) => {
  return (
    <img
      src="/images/img/icons/application-icon.svg"
      alt="Application Icon"
      aria-label="Application Icon"
      className={className}
    />
  );
};

export default ApplicationFormIcons;
