import React from 'react';
import DayjsUtils from '@date-io/dayjs';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { setLanguage } from '@/shared/actions';
import { Toaster } from '@/components/ui/toaster';
import { getLanguage } from '../services/GNRL';
import '../services/facebookGoogleScripts';
import { isPireApp } from '../services/platformCheck';
import store, { history, persistor } from '../services/store';
import ConnectedProtectedRoutes from './protectedRoutes/ConnectedProtectedRoutes';
import SideMenu from './sideMenu/SideMenu';
import Splash from './startup/Splash';

dayjs.locale(getLanguage());

// Disable console logs if WITH_LOGS is false
if (process.env.REACT_APP_WITH_LOGS) {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
}

if (isPireApp()) {
  // import('./pireAppStyle.css');
} else {
  import('./novovilleStyle.css');
}

class AppRender extends React.Component {
  componentDidMount() {
    this.favicon = document.getElementById('favicon');
  }

  initializeLanguage = () => {
    const language = localStorage.getItem('language');
    if (language) {
      store.dispatch(setLanguage(language));
    }
  };

  render() {
    const { errorDesc, initialProcessDone } = this.props;

    if (errorDesc) {
      return <MaintenancePage errorDesc={errorDesc} />;
    }

    if (!initialProcessDone) {
      return <Splash />;
    }

    return (
      <Provider store={store}>
        <PersistGate
          loading={<Splash />}
          persistor={persistor}
          onBeforeLift={this.initializeLanguage}
        >
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <ConnectedRouter history={history}>
              <Container>
                <SideMenu />
                <Toaster />
                <Switch>
                  <ConnectedProtectedRoutes />
                </Switch>
                <AppVersion />
              </Container>
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const Container = (props) => {
  return <div id="container">{props.children}</div>;
};

const AppVersion = () => {

  if (process.env.REACT_APP_ENVIRONMENT === 'production') return null;

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'flex-end',
        color: 'gray',
        zIndex: 1000,
        bottom: 0,
        right: 0,
      }}
    >
      {`version ${process.env.REACT_APP_VERSION}`}
    </div>
  );
};

const MaintenancePage = ({ errorDesc }) => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>{errorDesc}</div>
    </div>
  );
};

export default AppRender;
