import React from 'react';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

const EmptyApplicationComponent = () => {
  return (
    <div className="flex flex-1 ">
      <div className="m-auto flex flex-col items-center space-y-8 max-w-52">
        <img
          src="/images/img/empty_applications.png"
          alt="Empty"
          className="object-contain"
        />

        <Typography
          variant="semibold_18"
          className="text-center text-nickel text-wrap"
        >
          {translate('No applications available')}
        </Typography>
      </div>
    </div>
  );
};

export default EmptyApplicationComponent;
