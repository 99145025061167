import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';
import NovovilleLoadingState from '@/components/novoville/NovovilleLoadingState';
import EmptyApplicationComponent from '../EmptyApplicationComponent';
import { getSubmittedApplications } from '../actions';
import SubmittedApplicationsItem from './SubmittedApplicationsItem';

const SubmittedApplications = () => {
  const dispatch = useDispatch();

  const { loading, error, submittedApplications } = useSelector(
    (state) => state.applicationsReducer
  );

  const getSbmtdApplications = () => {
    dispatch(getSubmittedApplications());
  };

  useEffect(() => {
    getSbmtdApplications();
  }, []);

  return (
    <>
      {loading && (
        <div className="flex flex-1 flex-col relative rounded-sm">
          <NovovilleLoadingState />
        </div>
      )}
      {!loading && error && (
        <div className="flex space-y-5 m-auto flex-col justify-around items-center">
          <Typography variant="bold_24" className="text-center">
            {translate('defaultErrorTitle')}
          </Typography>
          <Typography variant="regular_16" className="text-center">
            {translate('APPLICATIONS.applicationsError')}
          </Typography>
          <div>
            <NovovilleButton
              variant="primary"
              text={translate('Try Again')}
              onClick={getSbmtdApplications}
            />
          </div>
        </div>
      )}
      {!loading && !error && !!submittedApplications.length && (
        <div>
          {submittedApplications.map((application) => (
            <SubmittedApplicationsItem
              key={application.created_at.name}
              application={application}
            />
          ))}
        </div>
      )}
      {!loading && !error && !submittedApplications.length && (
        <EmptyApplicationComponent />
      )}
    </>
  );
};

export default SubmittedApplications;
