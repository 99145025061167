import React, { memo, useCallback, useMemo, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import { toast } from '@/components/ui/use-toast';
import AvailableApplicationsVerificationContent from './AvailableApplicationsVerificationContent';

const AvailableApplicationsItem = ({
  availableApplication,
  setActiveApplication,
}) => {
  const {
    icon_url,
    title,
    name,
    fields_captured,
    user_can_submit,
    submit_error_code,
  } = availableApplication;

  const [shouldOpen, setShouldOpen] = useState(false);

  const requiredFields = useMemo(() => {
    if (!fields_captured) return [];
    return Object.keys(fields_captured).filter(
      (field) => fields_captured[field].required
    );
  }, [fields_captured]);

  const selectApplication = useCallback(() => {
    setActiveApplication(availableApplication);
    setShouldOpen(false);
  }, []);

  const triggerDialog = useCallback(() => {
    if (!user_can_submit) {
      toast({
        variant: 'info',
        title:
          translate(`api_${submit_error_code}`) ??
          translate('defaultErrorTitle'),
      });
    }
    if (fields_captured.length === 0) {
      selectApplication();
    }
  }, [fields_captured]);

  const dialogCanOpen = useMemo(() => {
    return user_can_submit && requiredFields.length > 0;
  });

  return (
    <Dialog
      open={shouldOpen && dialogCanOpen}
      onOpenChange={setShouldOpen}
      style={{
        height: 'inherit',
      }}
    >
      <DialogTrigger onClick={triggerDialog} asChild>
        <div
          className="
      flex flex-row justify-between items-center w-full
      px-5 py-11 bg-white cursor-pointer select-none
      border-b last:border-b-0 border-gray-200 active:bg-gray-50
      "
        >
          <img
            src={icon_url}
            alt={name}
            className="w-5 h-5 mx-2 transition-all duration-300"
          />
          <Typography
            variant="bold_16"
            className="flex-1 text-start select-none"
          >
            {title}
          </Typography>
          <ChevronRight className="w-5 h-5" />
        </div>
      </DialogTrigger>
      <AvailableApplicationsVerificationContent
        requiredFields={requiredFields}
        onVerify={selectApplication}
      />
    </Dialog>
  );
};

export default memo(AvailableApplicationsItem);
