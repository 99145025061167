import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import platform from 'platform';
import { translate } from './TranslationService';

dayjs.extend(weekday);

const store = window.store;

export const getPlatformData = () => platform;

export const getDeviceType = () => 'web';

//this is the method we should use everywhere for getting the language instead of accessing it through redux
export const getTranslatedLanguageWithUserSettings = () => {
  const translatedLanguages = ['en', 'el', 'fr'];
  const languageCode = getLanguage();
  if (languageCode === 'gr') {
    return 'el';
  }
  if (translatedLanguages.includes(languageCode)) {
    return languageCode;
  }
  return translatedLanguages[0];
};

export const getLanguage = () => {
  let systemLanguage = 'en'; // Default to English
  const settingLanguage = store?.getState()?.settingsReducer?.selectedLanguage;

  // Attempt to use the browser's language setting in a web environment
  if (typeof navigator !== 'undefined') {
    systemLanguage = navigator.language || navigator.userLanguage;
    systemLanguage = systemLanguage.split('-')[0]; // Convert "en-US" to "en"
  }

  // Use the user's setting or the system language, with a fallback to English
  const languageCode = settingLanguage || systemLanguage || 'en';
  return languageCode;
};

export const getInitialParams = ({ firebaseDynamicLinkParams } = {}) => {
  let urlParams = window.location.href.split('?')[1];
  return paramStringToObject(urlParams);
};

export function paramStringToObject(paramString) {
  let paramString2 = decodeURIComponent(paramString);
  let paramPairs = paramString2.split('&');

  let retObj = {};
  paramPairs.forEach((item) => {
    let keyVal = item.split('=');
    retObj[keyVal[0]] = keyVal[1];
  });

  return retObj;
}

export const removeAcccents = (input) => {
  if (!input || !input.normalize) {
    return remove_accents(input);
  }
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeAccentsAndUpper = (input) => {
  return removeAcccents(input).toUpperCase();
};

export const capitalizeFirstLetter = (text) => {
  return `${text[0].toUpperCase()}${text.slice(1)}`;
};

export const toUpperCaseFirstLetter = (input) => {
  return input.charAt(0).toUpperCase() + input.substring(1);
};
function remove_accents(strAccents) {
  let strAccentsArr = strAccents.split('');
  let strAccentsOut = [];
  let strAccentsLen = strAccentsArr.length;
  let accents = 'άέήίόύώΆΈΉΊΌΎΏ';
  let accentsOut = 'αεηιουωΑΕΗΙΟΥΩ';
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join('');

  return strAccentsOut;
}

export const looseStrCmpr = (string1, string2) => {
  if (
    removeAcccents(string1.toLowerCase()).indexOf(
      removeAcccents(string2.toLowerCase())
    ) === -1
  ) {
    return false;
  }
  return true;
};
//TODO: Revisit this
export const areFieldsValid = (fieldsObj) => {
  let result = Object.keys(fieldsObj).filter((key) => {
    let value = fieldsObj[key];

    if (key === 'email') {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //we re removing whitespaces as they're sanitised server side
      return !re.test(String(value).toLowerCase().replace(/\s/g, ''));
    }

    if (key === 'password') {
      return !(value.length > 5);
    }

    if (key === 'name' || key === 'surname' || key === 'nickname') {
      return !(value.length > 1);
    }

    if (key === 'phone') {
      let phone = value.toString().replace('+', '');
      return !(phone.length > 7 && phone.length < 16);
    }
    if (key === 'description') {
      return !(value.length > 10 && value.length < 1024);
    }
    if (key === 'address') {
      return !(value.length > 10 && value.length < 256);
    }
    return false;
  });

  return result.length === 0;
};

export const isInvalidPassword = (password) => {
  const PossibleErrors = {
    SIZE: 'PASSWORD_SIZE',
    CAPITAL_OR_SYMBOL: 'PASSWORD_CAPITAL_OR_SYMBOL',
    OBVIOUS_STRING: 'PASSWORD_OBVIOUS_STRING',
  };

  const obviousStrings = [
    '123456',
    'password',
    'qwerty',
    'abcdefgh',
    'username',
  ];
  const capitlRegex = new RegExp(/^(.*[A-Z].*)$/);
  const notSymbolRegex = new RegExp('^[a-zA-Z0-9 ]*$');
  if (password.length < 8) {
    return PossibleErrors.SIZE;
  }
  if (!capitlRegex.test(password) || notSymbolRegex.test(password)) {
    return PossibleErrors.CAPITAL_OR_SYMBOL;
  }
  if (
    obviousStrings.find((obvString) =>
      password.toLowerCase().includes(obvString)
    )
  ) {
    return PossibleErrors.OBVIOUS_STRING;
  }
  return false;
};

export const getErrorForFields = (fieldsObj, noPassword) => {
  let result;
  Object.keys(fieldsObj).forEach((key) => {
    let value = fieldsObj[key];

    if (key === 'email') {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //we re removing whitespaces as they're sanitised server side
      if (!re.test(String(value).toLowerCase().replace(/\s/g, ''))) {
        result = translate('Please insert a valid email');
      }
    }

    if (key === 'password' && !noPassword) {
      if (!(value.length > 5)) {
        result = translate('Password needs to be at least 6 digits');
      }
    }

    if (key === 'name') {
      if (!(value.length >= 2)) {
        //todo translate
        result = translate('NameNeedsToBeAtLeast2Chars');
      }
    }

    if (key === 'surname') {
      if (!(value.length >= 2)) {
        //todo translate
        result = translate('SurnameNeedsToBeAtLeast2Chars');
      }
    }

    if (key === 'nickname') {
      if (!(value.length > 2)) {
        //todo translate
        result = translate('NicknameNeedsToBeAtLeast3Chars');
      }
    }
  });

  return result;
};

export const wrapWithDelay = (promise, minDelay = 0) => {
  let timeStart = new Date().getTime();

  return promise.then((data) => {
    return new Promise((res) => {
      let timeEnd = new Date().getTime();

      let timeDifference = timeEnd - timeStart;

      if (timeDifference >= minDelay) {
        res(data);
      } else {
        setTimeout(() => {
          res(data);
        }, minDelay - timeDifference);
      }
    });
  });
};

function translatePoiToDayItem(day) {
  let open_times = [];
  if (!day) {
    return undefined;
  }
  day.times.forEach((time, index) => {
    let openTime = {
      start: time.open.time,
      end: time.close.time,
    };
    open_times.push(openTime);
  });

  let newDay = {
    day: dayjs().weekday(day.day).format('dddd'),
    open_times: open_times,
    is_open_all_day: false,
    is_closed: !day.is_open,
  };
  return newDay;
}

const isEmailPartOfNovovilleTeam = (email) => {
  const otherNovovilleEmails = [
    'kokkonas.dimitris@gmail.com',
    'mentoras28@gmail.com',
    'avgoustis.george.mail@gmail.com',
    'daillencour.t.louis@gmail.com',
  ];
  if (typeof email === 'string') {
    let [domain] = email.split('@');

    return domain === 'novoville.com' || otherNovovilleEmails.includes(email);
  }
  return false;
};
const isFullProfile = (profile) => {
  if (!profile.address || !profile.postalCode || !profile.mobile) {
    return false;
  }
  return true;
};

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const getNavigationScreenFromModuleName = (serverModule) => {
  const mapModuleToNavigationScreen = new Map();
  mapModuleToNavigationScreen.set('Home', 'Home');
  mapModuleToNavigationScreen.set('reports', 'Reports');
  mapModuleToNavigationScreen.set('mycity', 'MyCity');
  mapModuleToNavigationScreen.set('messages', 'Messages');
  mapModuleToNavigationScreen.set('phones', 'Phonebook');
  mapModuleToNavigationScreen.set('faqs', 'Faq');
  mapModuleToNavigationScreen.set('pois', 'MyCity');
  mapModuleToNavigationScreen.set('calendar', 'Calendar');
  mapModuleToNavigationScreen.set('statistics', 'Home');
  mapModuleToNavigationScreen.set('forms', 'Forms');
  mapModuleToNavigationScreen.set(
    'shared_repairs',
    'SharedRepairSwitchContainer'
  );
  mapModuleToNavigationScreen.set('wallet', 'ParkingContainer');
  mapModuleToNavigationScreen.set('parking', 'ParkingContainer');
  mapModuleToNavigationScreen.set('car_parks', 'ParkingContainer');
  mapModuleToNavigationScreen.set('discussions', 'Discussions');
  mapModuleToNavigationScreen.set('adopt_a_tree', 'MyCity');
  mapModuleToNavigationScreen.set('recycling_points', 'MyCity');
  mapModuleToNavigationScreen.set('get_volunteering', 'VolunteerMatches');
  mapModuleToNavigationScreen.set('fines', 'Fines');
  mapModuleToNavigationScreen.set('bills', 'Bills');
  const drawerScreen = mapModuleToNavigationScreen.get(serverModule);
  return drawerScreen;
};

const selectDistinctByKey = (array, key) => {
  const tempObject = groupBy(array, key);
  const result = Object.keys(tempObject)
    .map((key) => {
      if (key !== null) {
        return {
          ...tempObject[key][0],
          flatsOwned: tempObject[key].length,
        };
      }
    })
    .filter((item) => item);
  return result;
};

export {
  isFullProfile,
  translatePoiToDayItem,
  isEmailPartOfNovovilleTeam,
  selectDistinctByKey,
};
