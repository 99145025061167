import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';
import NovovilleLoadingState from '@/components/novoville/NovovilleLoadingState';
import EmptyApplicationComponent from '../EmptyApplicationComponent';
import { getApplicationsTypes } from '../actions';
import AvailableApplicationsItem from './AvailableApplicationsItem';

const AvailableApplications = ({ setActiveApplication }) => {
  const dispatch = useDispatch();

  const {
    loading,
    error,
    applicationTypes: availableApplications,
  } = useSelector((state) => state.applicationsReducer);

  const getApplicationTypes = useCallback(() => {
    dispatch(getApplicationsTypes());
  }, [dispatch]);

  useEffect(() => {
    getApplicationTypes();
  }, [getApplicationTypes]);

  return (
    <>
      {loading && (
        <div className="flex flex-1 flex-col relative rounded-sm">
          <NovovilleLoadingState />
        </div>
      )}
      {!loading && error && (
        <div className="flex space-y-5 m-auto flex-col justify-around items-center">
          <Typography variant="bold_24" className="text-center">
            {translate('defaultErrorTitle')}
          </Typography>
          <Typography variant="regular_16" className="text-center">
            {translate('APPLICATIONS.applicationsError')}
          </Typography>
          <div>
            <NovovilleButton
              variant="primary"
              text={translate('Try Again')}
              onClick={getApplicationTypes}
            />
          </div>
        </div>
      )}
      {!loading && !error && availableApplications.length > 0 && (
        <div className="flex flex-col w-full space-y-4">
          {availableApplications.map((application) => (
            <AvailableApplicationsItem
              availableApplication={application}
              key={application.name}
              setActiveApplication={setActiveApplication}
            />
          ))}
        </div>
      )}
      {!loading && !error && availableApplications.length === 0 && (
        <EmptyApplicationComponent />
      )}
    </>
  );
};

export default AvailableApplications;
