import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { translate } from '@/services/TranslationService';
import store from '@/services/store';
import { toast } from '@/components/ui/use-toast';
import NOVOLOADER from '@/shared/components/NOVOLoader';

const ActiveSelectedApplication = ({
  activeApplication,
  onResetActiveApplication,
  onApplicationSubmitted,
}) => {
  const { url, name } = activeApplication;
  const token = store.getState().authReducer.auth_token;
  const iframeRef = useRef(null);

  const secureUrl = `${url}&Token=${encodeURIComponent(token)}`;
  const urlOrigin = new URL(url).origin;

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const messageQueue = [];

    const handleMessage = (event) => {
      try {
        const { data, origin } = event;

        if (origin !== urlOrigin) return;

        const payload = (() => {
          try {
            return JSON.parse(data);
          } catch (jsonError) {
            throw new Error(`❌ Invalid JSON format received: ${data}`);
          }
        })();

        const { code, error } = payload;

        if (error) {
          throw new Error(
            !code || code === 0
              ? 'An unknown error occurred'
              : translate(`api_${code}`)
          );
        }

        toast({
          variant: 'success',
          title: translate(`api_${code}` || 'OTHER.success'),
        });

        onApplicationSubmitted();
      } catch (error) {
        toast({
          variant: 'destructive',
          title: error.message,
        });

        onResetActiveApplication();
      }
    };

    const handleEarlyMessage = (event) => {
      messageQueue.push(event);
    };

    window.addEventListener('message', handleEarlyMessage);

    setTimeout(() => {
      window.removeEventListener('message', handleEarlyMessage);
      window.addEventListener('message', handleMessage);

      messageQueue.forEach((event) => handleMessage(event));
    }, 500);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="flex flex-row justify-start items-center w-full p-4">
        <ArrowLeft
          className="cursor-pointer"
          onClick={onResetActiveApplication}
        />
      </div>
      {isIframeLoaded && <NOVOLOADER />}
      <iframe
        ref={iframeRef}
        title={name}
        src={secureUrl}
        className="flex-1"
        loading="lazy"
        onLoad={() => {
          setIsIframeLoaded(false);
        }}
      />
    </div>
  );
};

export default ActiveSelectedApplication;
