import React from 'react';
import dayjs from 'dayjs';
import { CalendarClock, Heart, Navigation2, Phone, XIcon } from 'lucide-react';
import { Popup } from 'react-mapbox-gl';
import {
  NovovilleBadge,
  NovovilleButton,
  Typography,
} from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import { getReportIcon } from '../reports/ReportItem';

const DESCRIPTION_LENGTH = 40;
class InfoWindow extends React.Component {
  state = {
    readMore: false,
    readLess: false,
  };

  componentDidMount() {
    if (this.props.selectedPoint.description.length > DESCRIPTION_LENGTH)
      this.setState({ readMore: true });
  }

  readMore = (paragraph) => {
    let displayParagraph = [];
    if (paragraph.length > DESCRIPTION_LENGTH) {
      const characters = [...paragraph];
      characters.forEach((character, index) => {
        index < 40 ? displayParagraph.push(character) : null;
      });
    } else return paragraph;

    return displayParagraph.join('');
  };

  render() {
    const {
      coordinates,
      userLocation,
      selectedPoint,
      subscribeToPoi,
      closeInfoWindow,
      unsubscribeFromPoi,
    } = this.props;

    const isTreePOI = selectedPoint.type === 'ADOPT_A_TREE';
    const isReportPoi = selectedPoint.status_name ? true : false;
    const isPoi = selectedPoint.type
      ? selectedPoint.type !== 'ADOPT_A_TREE'
      : false;

    return (
      <Popup
        coordinates={coordinates}
        closeOnClick={true}
        onClose={closeInfoWindow}
        className="infoWindow-container mt-[20px]"
        closeOnMove={true}
        keepInView={true}
        anchor={'center'}
      >
        <div className="sm:max-w-[240px] md:max-w-[310px] lg:max-w-[310px]">
          {isTreePOI && (
            <TreeInfoWindow
              poi={selectedPoint}
              subscribeToPoi={subscribeToPoi}
              unsubscribeFromPoi={unsubscribeFromPoi}
              translate={translate}
              readMore={this.readMore.bind(this)}
              closeInfoWindow={closeInfoWindow}
            />
          )}
          {isReportPoi && (
            <ReportInfoWindow
              translate={translate}
              getReportIcon={getReportIcon}
              selectedPoint={selectedPoint}
              closeInfoWindow={closeInfoWindow}
            />
          )}
          {isPoi && (
            <div className="flex flex-col p-[14px] pt-0">
              <div className="flex justify-end pb-[10px] mr-[-12px] cursor-pointer">
                <XIcon
                  className="w-[16px] h-[16px] text-gray-500"
                  onClick={closeInfoWindow}
                />
              </div>
              {selectedPoint.photo && (
                <img
                  className={'infoWindowImage rounded-[6px]'}
                  src={
                    selectedPoint.photo
                      ? selectedPoint.photo
                      : '/images2/other/Default_image_messages.png'
                  }
                  alt={selectedPoint.title}
                />
              )}
              <Typography
                variant="semibold_14"
                className="text-gray-800 break-words pt-[8px]"
                style={{
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                  whiteSpace: 'normal',
                }}
              >
                {selectedPoint.title}
              </Typography>

              <Separator className="my-[6px]" />

              {this.state.readMore ? (
                <div className="flex flex-col gap-[8px]">
                  <Typography
                    variant="regular_14"
                    className="text-gray-800"
                  >{`${this.readMore(selectedPoint.description)}...`}</Typography>
                  <Typography
                    variant="semibold_16"
                    className="cursor-pointer text-blueLink underline hover:text-blueLinkHover"
                    onClick={() => {
                      this.setState({ readMore: false, readLess: true });
                    }}
                  >
                    {translate('Read More')}
                  </Typography>
                </div>
              ) : (
                <div className="flex flex-col gap-[8px]">
                  <Typography variant="regular_14" className="text-gray-800">
                    {selectedPoint.description}
                  </Typography>
                  {this.state.readLess && (
                    <Typography
                      variant="semibold_16"
                      className="cursor-pointer text-blueLink underline hover:text-blueLinkHover"
                      onClick={() => {
                        this.setState({ readMore: true, readLess: false });
                      }}
                    >
                      {translate('Close')}
                    </Typography>
                  )}
                </div>
              )}
              <div className="flex justify-between gap-[14px] pt-[16px]">
                <NovovilleButton
                  variant="primary"
                  className="w-full"
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?saddr=${parseFloat(userLocation.lat)},${parseFloat(userLocation.lng)}&daddr=${parseFloat(selectedPoint.latitude)},${parseFloat(selectedPoint.longitude)}`,
                      '_blank'
                    );
                  }}
                >
                  <Navigation2 className="w-[16px] h-[16px] text-gray-800 mr-[8px]" />
                  <span className="hidden sm:block">
                    {translate('ADDRESS')}
                  </span>
                </NovovilleButton>
                <NovovilleButton
                  variant="secondary"
                  className="w-full"
                  onClick={() => {
                    window.open(`tel:${selectedPoint.phone}`);
                  }}
                >
                  <Phone className="w-[16px] h-[16px] text-gray-800 mr-[8px]" />
                  <span className="hidden sm:block">{translate('Phone')}</span>
                </NovovilleButton>
              </div>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

//ADOPT_A_TREE INFOWINDOW
class TreeInfoWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPOISubscription: false,
    };
  }

  horizontalScroll(e) {
    const horizontalContainer = document.querySelector(
      '.horizontal-scroll-wrapper'
    );
    const x = e.pageX - horizontalContainer.getBoundingClientRect().left;
    horizontalContainer.scrollTo({
      top: 0,
      left: x,
    });

    requestAnimationFrame(() => this.horizontalScroll);
    // this.toggleArrows()
  }

  handleSubscrition(poi) {
    let promise;
    this.setState({ loadingPOISubscription: true });
    if (poi.is_subscribed) promise = this.props.unsubscribeFromPoi(poi);
    else promise = this.props.subscribeToPoi(poi);

    promise
      .catch((err) => {
        toast({
          title: translate('OTHER.errorOcurred'),
          description: err.details.user_description,
          variant: 'destructive',
        });
      })
      .finally(() => {
        this.setState({ loadingPOISubscription: false });
      });
  }

  render() {
    const { poi, translate } = this.props;

    return (
      <div className="flex flex-col p-[14px] pt-0">
        <div className="flex justify-end pb-[10px] mr-[-12px] cursor-pointer">
          <XIcon
            className="w-[16px] h-[16px] text-gray-500"
            onClick={this.props.closeInfoWindow}
          />
        </div>
        <img
          className={'infoWindowImage rounded-[6px]'}
          src={poi.photo}
          alt={poi.title}
        />
        <div className="flex flex-col align-start gap-[8px] pt-[12px]">
          <Typography variant="bold_16" className="text-gray-800">
            {poi.title}
          </Typography>
          <div className="flex justify-between gap-[14px] pt-[16px]">
            <NovovilleButton
              variant="secondary"
              className="w-full bg-errorRed text-white"
              onClick={() => {
                this.props.handleSubscrition(poi);
              }}
            >
              <Heart className="w-[16px] h-[16px] text-whit mr-[8px]" />
              <span className="hidden sm:block">
                {!poi.is_subscribed
                  ? translate('ADOPT_ME')
                  : translate('CANCEL_ADOPTION')}
              </span>
            </NovovilleButton>
            <NovovilleButton
              variant="secondary"
              className="w-full"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/@${parseFloat(poi.latitude)},${parseFloat(poi.longitude)}/?q=${parseFloat(poi.latitude)},${parseFloat(poi.longitude)}`,
                  '_blank'
                );
              }}
            >
              <Navigation2 className="w-[16px] h-[16px] text-gray-800 mr-[8px]" />
              <span className="hidden sm:block">{translate('ADDRESS')}</span>
            </NovovilleButton>
          </div>
        </div>
      </div>
    );
  }
}

const formatDateString = (dateString) => {
  return dayjs(dateString).format('DD/MM/YYYY HH:mm');
};

const ReportInfoWindow = (props) => {
  const { selectedPoint, translate, closeInfoWindow } = props;
  return (
    <div className="flex flex-col p-[14px] pt-0">
      <div className="flex justify-end pb-[10px] mr-[-12px] cursor-pointer">
        <XIcon
          className="w-[16px] h-[16px] text-gray-500"
          onClick={closeInfoWindow}
        />
      </div>
      <img
        className={'infoWindowImage rounded-[6px]'}
        alt={selectedPoint.title}
        src={
          selectedPoint.photo
            ? selectedPoint.photo
            : '/images2/other/Default_image_messages.png'
        }
      />
      <div className="flex flex-col align-start gap-[8px] pt-[12px]">
        <Typography variant="bold_16" className="text-gray-800">
          {translate(selectedPoint.issue_name)}
        </Typography>
        <NovovilleBadge status={selectedPoint.status_name} className="w-fit" />
        <div className="flex items-center gap-[4px]">
          <CalendarClock className="w-[12px] h-[12px] text-[#878889]" />
          <Typography variant="regular_12" className="text-[#878889]">
            {formatDateString(selectedPoint.created_at)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default InfoWindow;
