import React, { memo } from 'react';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

const ApplicationAvailabilitySelection = ({
  options,
  selected,
  setSelected,
}) => {
  return (
    <div className="flex-1 flex mx-2">
      <div className="flex border-2 border-strongCyan w-fit">
        {Object.entries(options).map(([key, value]) => (
          <label
            key={key}
            id={`label-${key}`}
            className="has-[:checked]:bg-strongCyan py-3 px-12 flex-1"
          >
            <input
              type="radio"
              name="availability_selection"
              id={`input-${key}`}
              checked={key === selected}
              onClick={() => setSelected(key)}
              className="peer hidden"
              readOnly
            />
            <Typography className="select-none" variant={'semibold_16'}>
              {translate(value.label)}
            </Typography>
          </label>
        ))}
      </div>
    </div>
  );
};

export default memo(ApplicationAvailabilitySelection);
