import { formatPhoneNumber } from '@/lib/utils';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goTo, goToAddMobile, goToVerifyEmail } from '@/shared/actions';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog';
import UnverifiedIcon from '../../../resources/Unverified.svg';
import VerifiedIcon from '../../../resources/Verified.svg';

const AvailableApplicationsVerificationContent = ({
  requiredFields,
  onVerify,
}) => {
  const dispatch = useDispatch();

  const hasVerifiedEmail = useSelector(
    (state) => state.profileReducer.hasVerifiedEmail
  );
  const hasVerifiedPhoneNumber = useSelector(
    (state) => state.profileReducer.hasVerifiedPhoneNumber
  );
  const profile = useSelector((state) => state.profileReducer.profile) || {};
  const { address, name, surname, landline, email, mobile } = profile;

  const needsVerificationObject = {
    email: !hasVerifiedEmail,
    mobile: !hasVerifiedPhoneNumber,
    address: !address,
    name: !name,
    surname: !surname,
    landline: !landline,
  };

  const needsVerificationValues = {
    email,
    mobile: formatPhoneNumber({
      phoneNumber: mobile || '',
      needsPlusSign: true,
    }),
    address,
    name,
    surname,
    landline,
  };

  const handleIcon = useCallback((verified) => {
    return verified ? VerifiedIcon : UnverifiedIcon;
  }, []);

  const handleVerificationText = useCallback((verified) => {
    return verified
      ? `(${translate('verified')})`
      : `(${translate('needs verification')})`;
  }, []);

  const renderButton = useCallback(() => {
    if (hasVerifiedEmail === false)
      return (
        <NovovilleButton
          type="submit"
          variant={'primary'}
          onClick={() => {
            dispatch(goToVerifyEmail('applications'));
          }}
        >
          {translate('continue')}
        </NovovilleButton>
      );

    if (hasVerifiedPhoneNumber === false)
      return (
        <NovovilleButton
          type="submit"
          variant={'primary'}
          onClick={() => {
            dispatch(goToAddMobile('applications'));
          }}
        >
          {translate('continue')}
        </NovovilleButton>
      );

    const hasUnverifiedFields = requiredFields.some(
      (field) => needsVerificationObject[field]
    );

    if (hasUnverifiedFields)
      return (
        <NovovilleButton
          type="submit"
          variant={'primary'}
          onClick={() => {
            dispatch(goTo('profile'));
          }}
        >
          {translate('continue')}
        </NovovilleButton>
      );

    return (
      <NovovilleButton
        type="submit"
        variant={'primary'}
        onClick={() => {
          onVerify();
        }}
      >
        {translate('continue')}
      </NovovilleButton>
    );
  }, []);

  return (
    <DialogContent>
      <DialogHeader className="text-start">
        <Typography variant="bold_20">{translate('Verification')}</Typography>
        <Typography variant="normal_14">
          {translate('verifyScreen_text')}
        </Typography>
      </DialogHeader>
      <div className="flex flex-col py-[32px] gap-[24px]">
        {requiredFields.map((field) => (
          <div key={field} className="flex flex-row">
            <img
              src={handleIcon(!needsVerificationObject[field])}
              alt="verified_icon"
            />
            <div className="flex flex-col">
              <div className="flex flex-row align-center">
                <Typography variant="semibold_16" className="mr-2">
                  {translate(field)}:
                </Typography>
                <Typography variant="regular_16">
                  {needsVerificationValues[field]}
                </Typography>
              </div>
              <Typography variant="italic_14">
                {handleVerificationText(!needsVerificationObject[field])}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <DialogFooter>{renderButton()}</DialogFooter>
    </DialogContent>
  );
};

export default AvailableApplicationsVerificationContent;
