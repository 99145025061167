import * as AType from '../../shared/actionTypes';

export const applicationsReducer = (state, action) => {
  if (!state) {
    state = {
      activeApplication: null,
      applicationTypes: [],
      submittedApplications: [],
      loading: true,
      error: null,
    };
  }

  switch (action.type) {
    case AType.GET_APPLICATIONS_TYPE_REQ: {
      return {
        ...state,
        applicationTypes: [],
        loading: true,
        error: null,
      };
    }
    case AType.GET_APPLICATIONS_TYPE_RES: {
      return {
        ...state,
        applicationTypes: action.payload,
        loading: false,
        error: null,
      };
    }
    case AType.GET_APPLICATIONS_TYPE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case AType.SET_ACTIVE_APPLICATION: {
      return {
        ...state,
        activeApplication: action.payload,
      };
    }
    case AType.GET_SUBMITTED_APPLICATIONS_REQ: {
      return {
        ...state,
        submittedApplications: [],
        loading: true,
        error: null,
      };
    }
    case AType.GET_SUBMITTED_APPLICATIONS_RES: {
      return {
        ...state,
        submittedApplications: action.payload,
        loading: false,
        error: null,
      };
    }
    case AType.GET_SUBMITTED_APPLICATIONS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
