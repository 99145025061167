import AvailableApplications from './AvailableApplications/AvailableApplications';
import SubmittedApplications from './SubmittedApplications/SubmittedApplications';

export const APPLICATIONS_SELECTIONS = {
  available: {
    id: 'AVAILABLE',
    label: 'Available',
    component: AvailableApplications,
  },
  submitted: {
    id: 'SUBMITTED',
    label: 'Submitted',
    component: SubmittedApplications,
  },
};
