import { appNames, getApplicationName } from './services/platformCheck';

let config = {
  customMedia: {
    mobile: 426,
    recaptchaCompactThreshold: 360,
  },
  colors: {
    novoDark: '#212034',
    lightGrayBG: '#F7F7FA',
    textDarkColor: 'black',
    reports: {
      red: '#e53563',
      yellow: '#f0d247',
      teal: '#29e4e2',
    },
    white: '#FFF',
    backgroundGray: '#f5f7fc',
    darkBlue: '#292840',
    linkBlue: '#007aFF',
    lightGrayText: '#bac9d9',
    grayText: '#808b96',
    darkText: '#212034',
    novoColor: '#35d8b7',
    placeHolderColor: '#454F63',
    lightPlaceHolderColor: '#454F6345',
    shadowColor: '#455B63',
    seperatorGray: '#CFD3D7',
    novoRedColor: '#E02E58',
    green: '#79CBC2',
    walletPurple: '#5773FF',
    menuBackground: '#677A8A',
    // shared repairs start
    buttonYellow: '#FFA936',
    buttonBlue: '#3C69D5',
    textInputBorderColor: '#212034',
    cardTitle: '#212034',
    cardSubitle: '#212034',
    cardMeta: '#bac9d9',
    //shared repairs end
    pireappColor: '#ed3223',
  },
  fontSizes: {
    mainSize: 15,
    forgotPass: 14,
    headerTitle: 12,
    messageFilter: 10,
    calloutTitleSize: 16,
    faqTitleSize: 17,
    //shared repairs start
    cardTitle: 15,
    cardSubitle: 12,
    cardMeta: 12,
    addItemLabelSize: 13,
    //shared repairs end
  },
  other: {
    cardBorderRadius: 12,
    //shared repairs start
    distanceBetweenelements: 20,
    halfDistanceBetweenelements: 10,
    cardTitleFontWeight: 'bold',
    fab_height: 46,
    descriptionTextAreaHeight: 48,

    //shared repairs end
  },
  opacitys: {
    cardShadowOpacity: 0.3,
    imageShadowOpacity: 0.3,
  },
  statisticsColors: [
    '30d7bd',
    'f0d247',
    'e53563',
    '85bd56',
    '355ea0',
    '9474ab',
    '598067',
    'c36e1b',
    '83a4c4',
    '8a8055',
  ],
  NSR: {
    grid: {
      spaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      center: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    other: {
      dropDownRow: {
        backgroundColor: '#FFFFFF',
        flex: 0,
        padding: 10,
        borderRadius: 9,
      },
      checkBox: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#CCCFE2',
      },
      descriptionTextAreaHeight: 60,
      distanceTopAndBottom: 35,
      distance_15: 15,
      distance_16: 16,
    },
    colors: {
      white: '#FFFFFF',
      whiteSmoke: '#F4F4F7',
      lightGrey: '#F6F6F9',
      blue: '#3E7BED',
      lightgrayishBlue: '#ECEEF5',
      grayishBlue: '#A1A3BC',
      darkGrayishBlue: '#737486',
      darkBlue: '#1f202f',
      textDarkColor: '#0e1026',
      lightOrange: '#ff9a5c',
      orange: '#E88C5B',
      selectTraderBg: '#F7F7F9',
      repairDetailsBg: '#F8F8FA',
      starColor: '#F29538',
      traderBgIcon: '#EFF1F8',
      yellow: '#FDA144',
      progressCircleGreen: '#55b79a',
      progressCircleRed: '#ef8675',
      progressCircleGrey: '#b7bace',
      nickel: '#6E6F77',
    },
    typography: {
      bold_13: {
        fontSize: 13,
        lineHeight: 19,
        fontFamily: 'OpenSans-Bold',
      },
      bold_12: {
        fontSize: 12,
        lineHeight: 15,
        fontFamily: 'OpenSans-Bold',
      },
      bold_14: {
        fontSize: 14,
        lineHeight: 17,
        fontFamily: 'OpenSans-Bold',
      },
      bold_15: {
        fontSize: 15,
        lineHeight: 24,
        fontFamily: 'OpenSans-Bold',
      },
      bold_17: {
        fontSize: 17,
        lineHeight: 20,
        fontFamily: 'OpenSans-Bold',
      },
      medium_19: {
        fontSize: 19,
        lineHeight: 23,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_18: {
        fontSize: 18,
        lineHeight: 22,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_17: {
        fontSize: 17,
        lineHeight: 20,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_16: {
        fontSize: 16,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_15: {
        fontSize: 15,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
      },
      mediumItalic_15: {
        fontSize: 15,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBoldItalic',
      },
      medium_14: {
        fontSize: 14,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_13: {
        fontSize: 13,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_12: {
        fontSize: 12,
        lineHeight: 15,
        fontFamily: 'OpenSans-SemiBold',
      },
      medium_10: {
        fontSize: 10,
        lineHeight: 13,
        fontFamily: 'OpenSans-SemiBold',
      },
      regular_15: {
        fontSize: 15,
        lineHeight: 19,
        fontFamily: 'OpenSans-Regular',
      },
      regular_14: {
        fontSize: 14,
        lineHeight: 18,
        fontFamily: 'OpenSans-Regular',
      },
      regularItalic_14: {
        fontSize: 14,
        lineHeight: 18,
        fontFamily: 'OpenSans-Italic',
      },
      regularItalic_13: {
        fontSize: 13,
        lineHeight: 16,
        fontFamily: 'OpenSans-Italic',
      },
      regular_13: {
        fontSize: 13,
        lineHeight: 16,
        fontFamily: 'OpenSans-Regular',
      },
      regular_12: {
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'OpenSans-Regular',
      },
      regular_10: {
        fontSize: 10,
        lineHeight: 13,
        fontFamily: 'OpenSans-Regular',
      },
      headerTitle: {
        fontSize: 15,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
        color: '#1f202f',
      },
      buttonText: {
        fontSize: 15,
        lineHeight: 19,
        fontFamily: 'OpenSans-SemiBold',
      },
      faqButtonText: {
        fontSize: 14,
        lineHeight: 18,
        fontFamily: 'OpenSans-SemiBold',
        color: '#3E7BED',
      },
      labelInput: {
        fontSize: 14,
        lineHeight: 18,
        fontFamily: 'OpenSans-SemiBold',
        color: '#B6BAD0',
      },
      focusedLabelInput: {
        fontSize: 11,
        lineHeight: 14,
        fontFamily: 'OpenSans-SemiBold',
        color: '#B6BAD0',
      },
      textInput: {
        fontSize: 14,
        lineHeight: 18,
        fontFamily: 'OpenSans-SemiBold',
        color: '#1F202F',
      },
    },
  }, //shared repairs end
  GV: {
    colors: {
      darkGrayishBlue: '#999ead',
    },
  },
};

if (getApplicationName() === appNames.SWIFTPARKING) {
  //oneapp
  // mainColor: "#3C1053"
  // calendarBlue: "#0094DF"
  // calendarGreen: "#00DF89"
  // checkParkingBlue: "#314AD3"

  config['colors']['green'] = '#3C1053';
  config['colors']['walletPurple'] = '#3C1053';
  config['colors']['menuBackground'] = '#3C1053';
  config['colors']['novoColor'] = '#3C1053';
}
export default config;
