import { firstUpperCaseLetter } from '@/lib/utils';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CalendarDays } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getLanguage } from '@/services/GRNLwithoutDep';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import CalendarEventError from './CalendarEventError';
import CalendarEventLoading from './CalendarEventLoading';
import { getCalendarEvent } from './actions';

const DEFAULT_IMAGE = '/images/img/Default_image_messages.png';

const CalendarEvent = (props) => {
  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState(DEFAULT_IMAGE);

  const { isLoading, error, calendarEvent } = useSelector(
    (state) => state.calendarReducer
  );

  const { id, photo, body, start_at, title, type } = calendarEvent || {};

  const { eventId } = props.match.params;

  const { setHeaderTitle } = props;
  const [language, setLanguage] = useState(getLanguage());

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.Calendar'));
    setLanguage(getLanguage());
    dispatch(getCalendarEvent(eventId));
  }, [dispatch, language]);

  return (
    <div id={id} className="flex flex-1">
      {isLoading && <CalendarEventLoading />}
      {error && <CalendarEventError />}
      {calendarEvent && (
        <div className="m-10 w-full flex flex-col bg-white rounded-xl max-h-[calc(100vh-10rem)] h-fit overflow-y-auto p-8 ">
          {photo && (
            <div className="flex-1 flex items-center justify-center">
              <img
                src={imageSrc}
                alt={title}
                className="sm:max-h-72 max-h-56 mb-8 object-contain"
                loading="lazy"
                onLoad={() => setImageSrc(photo || DEFAULT_IMAGE)}
                onError={() => setImageSrc(DEFAULT_IMAGE)}
              />
            </div>
          )}
          <div className="flex flex-col flex-shrink-0 space-y-2">
            <div className="flex justify-between py-2 border-b-[1px] border-gray-300">
              <div className="flex">
                <CalendarDays size={24} className="text-purpleLink" />
                <Typography variant={'semibold_16'}>
                  {firstUpperCaseLetter(translate(type) || translate('event'))}
                </Typography>
              </div>
              <div className="flex">
                <Typography variant={'regular_16'} className="text-gray-500">
                  {dayjs(start_at).format('dddd, DD MMM YYYY')}
                </Typography>
              </div>
            </div>
            <Typography variant={'bold_20'} className="text-gray-800">
              {title}
            </Typography>
            <Typography variant={'regular_16'}>{body}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default withNOVOHeader(withRouter(CalendarEvent));
