import React, { useEffect, useReducer, useRef, useState } from 'react';
import _ from 'lodash';
import { Eye, EyeOff } from 'lucide-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import {
  NovovilleButton,
  NovovilleInput,
  Typography,
} from '@/components/novoville';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import novoville_logo from '../../resources/novoville_logo.svg';
import { areFieldsValid, getLanguage } from '../../services/GNRL';
import { makeid } from '../../services/NOVOGNRL';
import { translate } from '../../services/TranslationService';
import AuthHeader from '../AuthHeader/AuthHeader';
import { setCameFrom } from '../home/actions';
import { loginProcessAndRoute } from '../login/actions';
import { registerProcessAndRoute } from './actions';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

const initialState = {
  email: isDev ? makeid() + 'd@a.com' : '',
  password: isDev ? 'qwerty' : '',
  name: isDev ? 'TESTWEB' : '',
  surname: isDev ? 'TESTWEBSURNAME' : '',
  loading: false,
  errorMessage: '',
  language: getLanguage(),
  showPassword: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.message };
    case 'SET_LOADING':
      return { ...state, loading: action.loading };
    case 'SET_LANGUAGE':
      return { ...state, language: action.language };
    case 'TOGGLE_PASSWORD_VISIBILITY':
      return { ...state, showPassword: !state.showPassword };
    default:
      return state;
  }
}

const RegisterComponent = () => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const {
    email,
    password,
    name,
    surname,
    loading,
    language,
    errorMessage,
    showPassword,
  } = state;

  const [recaptchaKey, setRecaptchaKey] = useState(0);

  useEffect(() => {
    dispatchState({ type: 'SET_LANGUAGE', language: getLanguage() });
  }, []);

  const handleLanguageChange = (newLanguage) => {
    dispatchState({ type: 'SET_LANGUAGE', language: newLanguage });
    setRecaptchaKey((prevKey) => prevKey + 1);
  };

  const togglePasswordVisibility = () => {
    dispatchState({ type: 'TOGGLE_PASSWORD_VISIBILITY' });
  };

  const fieldsValid = areFieldsValid({ email, password, name, surname });

  const onChangeInputFields = (field, value) => {
    if (value.length > 100) return;
    dispatchState({ type: 'SET_FIELD', field, value });
  };

  const onCaptchaChange = (captchaResponse) => {
    dispatchState({
      type: 'SET_FIELD',
      field: 'captchaResponse',
      value: captchaResponse,
    });
  };

  const register = () => {
    const { email, password, name, surname } = state;
    dispatchState({ type: 'SET_LOADING', loading: true });
    dispatchState({ type: 'SET_ERROR_MESSAGE', message: '' });

    const captchaValueParam = recaptchaRef.current.getValue();
    const captchaKey = process.env.REACT_APP_NV_CAPTCHA_KEY;

    if (!captchaValueParam) {
      dispatchState({
        type: 'SET_ERROR_MESSAGE',
        message: 'Please solve the captcha to proceed.',
      });
      return;
    }

    dispatch(
      registerProcessAndRoute(
        email,
        password,
        name,
        surname,
        captchaKey,
        captchaValueParam
      )
    )
      .catch((err) => {
        dispatchState({
          type: 'SET_ERROR_MESSAGE',
          message: err.details.user_description,
        });
      })
      .finally(() => {
        dispatchState({ type: 'SET_LOADING', loading: false });
      });
  };

  const registerWithSocial = (social) => {
    dispatchState({ type: 'SET_LOADING', loading: true });
    dispatchState({ type: 'SET_ERROR_MESSAGE', message: '' });

    dispatch(loginProcessAndRoute(null, null, social))
      .catch((err) => {
        dispatchState({
          type: 'SET_ERROR_MESSAGE',
          message: _.get(err, 'details.user_description', 'An error occurred'),
        });
      })
      .finally(() => {
        dispatchState({ type: 'SET_LOADING', loading: false });
      });
  };

  return (
    <div className="flex flex-wrap bg-white">
      <div className="h-[400vh] w-full min-h-[100vh] bg-white overflow-hidden fixed" />
      <AuthHeader
        logo={novoville_logo}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="min-h-[calc(100vh-100px)] w-screen flex flex-col justify-center sm:py-[74px] py-[24px] sm:px-8">
        <form
          onSubmit={(e) => {
            register();
            e.preventDefault();
          }}
          className="w-full max-w-md self-center"
        >
          <Card className="w-full sm:p-[40px] sm:drop-shadow-md drop-shadow-none shadow-none text-gray-800 px-[24px] border-none">
            <CardHeader className="text-center p-0 pb-[32px]">
              <CardTitle className="text-3xl pb-[12px]">
                <Typography variant="semibold_24">
                  {translate('Register_title')}
                </Typography>
              </CardTitle>
              <CardDescription className="text-[16px] mt-0 text-gray-700">
                <Typography variant="regular_16">
                  {translate('enter_details')}
                </Typography>
              </CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <div className="flex flex-row gap-[24px]">
                <div className="grid gap-2 pb-[16px]">
                  <Typography variant="regular_14" error={errorMessage}>
                    {translate('name')}
                  </Typography>
                  <NovovilleInput
                    id="name"
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={(e) =>
                      onChangeInputFields('name', e.target.value)
                    }
                    required
                  />
                </div>
                <div className="grid gap-2 pb-[16px]">
                  <Label
                    className={`leading-[20px] font-medium ${
                      errorMessage ? 'text-errorRed' : ''
                    }`}
                    htmlFor="surname"
                  >
                    {translate('surname')}
                  </Label>
                  <NovovilleInput
                    id="surname"
                    type="text"
                    name="surname"
                    value={state.surname}
                    onChange={(e) =>
                      onChangeInputFields('surname', e.target.value)
                    }
                    required
                  />
                </div>
              </div>
              <div className="pb-[24px] grid gap-2">
                <Label htmlFor="email" className={'leading-[20px] font-medium'}>
                  Email
                </Label>
                <NovovilleInput
                  id="email"
                  type="email"
                  name="username"
                  autocomplete="username"
                  autofocus={true}
                  value={email}
                  onChange={(e) => onChangeInputFields('email', e.target.value)}
                />
              </div>
              <div className="grid gap-2 pb-[16px] relative">
                <Label
                  htmlFor="password"
                  className={`leading-[20px] ${
                    errorMessage ? 'text-errorRed' : ''
                  }`}
                >
                  {translate('Password')}
                </Label>
                <NovovilleInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) =>
                    onChangeInputFields('password', e.target.value)
                  }
                  errorMessage={errorMessage}
                  iconRight={
                    showPassword ? <Eye size={20} /> : <EyeOff size={20} />
                  }
                  onIconClick={togglePasswordVisibility}
                  required
                />
              </div>
              {errorMessage && (
                <div style={{ wordWrap: 'break-word' }}>
                  <Typography
                    variant="regular_14"
                    className="text-errorRed"
                    error={!!errorMessage}
                  >
                    {errorMessage}
                  </Typography>
                </div>
              )}
            </CardContent>
            <CardFooter className="p-0 flex flex-col justify-center items-center">
              <ReCAPTCHA
                key={recaptchaKey}
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  display: 'flex',
                  width: '50%',
                  justifyContent: 'center',
                }}
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_NV_CAPTCHA_KEY}
                onChange={onCaptchaChange}
                hl={language === 'gr' ? 'el' : 'en'}
              />
              <NovovilleButton
                disabled={loading || !fieldsValid}
                onClick={() => {
                  dispatch(setCameFrom('register'));
                  register();
                }}
                variant="primary"
                text={translate('Register_title')}
              />
              <div className="w-full py-[32px] overflow-hidden flex flex-row justify-center items-center">
                <Separator className="w-full" />
                <Typography
                  variant="regular_14"
                  className="text-nowrap px-[10px] w-full text-gray-700"
                >
                  {translate('register_with')}
                </Typography>
                <Separator className="w-full" />
              </div>
              <div className="w-full pb-[16px]">
                <NovovilleButton
                  disabled={loading}
                  onClick={() => registerWithSocial('FACEBOOK')}
                  variant="secondary"
                  social="Facebook"
                  text={translate('continue_facebook')}
                />
              </div>
              <div className="w-full pb-[32px]">
                <NovovilleButton
                  disabled={loading}
                  onClick={() => registerWithSocial('GOOGLE')}
                  social="Google"
                  variant="secondary"
                  text={translate('continue_google')}
                />
              </div>
              <div
                className={`w-full text-nowrap gap-[4px] py-[32px] sm:py-[0px] px-[16px] flex flex-col justify-center items-center text-gray-700 ${
                  language === 'gr' ? 'sm:flex-col' : 'sm:flex-row'
                }`}
              >
                <Typography variant="regular_16">
                  {`${translate('already_registered')} `}
                </Typography>
                <NovovilleButton
                  variant="link"
                  text={translate('Login')}
                  className="underline leading-[24px]"
                  to="/login"
                />
              </div>
            </CardFooter>
          </Card>
        </form>
        <div className="w-full items-center flex flex-col text-slate-500 z-10 text-sm font-normal font-['Open Sans'] leading-tight py-[24px] sm:py-[40px] left-0 bottom-0 text-center">
          <NovovilleButton
            variant="link"
            className=""
            to={
              language === 'gr'
                ? 'https://novoville.com/el/%cf%8c%cf%81%ce%bf%ce%b9-%ce%ba%ce%b1%ce%b9-%cf%80%cf%81%ce%bf%cf%8b%cf%80%ce%bf%ce%b8%ce%ad%cf%83%ce%b5%ce%b9%cf%82/'
                : 'https://novoville.com/terms-and-conditions/'
            }
          >
            <Typography variant="regular_14" className="text-gray-500">
              {translate('agreeWith')}
            </Typography>
          </NovovilleButton>
        </div>
      </div>
    </div>
  );
};

export default RegisterComponent;
