import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from '@/shared/components/withTranslation';
import { messageDetailEvent } from '../../services/analyticsActions';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import MessageItem from './MessageItem';
import PollMessageItem from './PollMessageItem';
import { getSingleMessage } from './actions';

class MessageDetails extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loading: false,
    };
    this.props.setHeaderTitle(this.props.translate('sideMenu.Messages'));
  }

  componentDidMount() {
    if (!this.props.message) {
      let data = this.props.location.pathname.split('/');
      let messageId = data[2];
      this.props.getSingleMessage(messageId);
    } else {
      let data = this.props.location.pathname.split('/');
      let messageId = data[2];
      if (this.props.message.id !== messageId) {
        this.props.getSingleMessage(messageId);
      }
      const { messageProp = this.props.message, cameFrom } = this.props;
      const message = Array.isArray(messageProp) ? messageProp[0] : messageProp;
      if (cameFrom) messageDetailEvent(cameFrom, message.app_type, message.id);
    }
  }

  render() {
    const { loading, message } = this.props;
    if (loading || !message) return null;

    const messageToShow = Array.isArray(message) ? message[0] : message;

    return (
      <div className="flex flex-row w-full h-fit cursor-default">
        {messageToShow.app_type === 'POLL' ? (
          <PollMessageItem
            message={messageToShow}
            imageWidth="sm:w-[340px] w-full"
            className="w-full rounded-md"
          />
        ) : (
          <div className="w-full p-0 sm:p-[40px]">
            <MessageItem
              message={messageToShow}
              imageWidth="sm:w-[340px] w-full"
              className="w-full rounded-0 sm:rounded-[12px]"
              listView={false}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSingleMessage,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  return {
    message: get(state, 'routing.location.state.message')
      ? get(state, 'routing.location.state.message')
      : state.messagesReducer.activeMessage,
    cameFrom: get(state, 'routing.location.state.cameFrom'),
    loading: state.messagesReducer.loading,
  };
};

export default withTranslation(
  withNOVOHeader(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageDetails))
  )
);
