import { goBack, push, replace } from 'connected-react-router';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  getAllAuthoritiesForCountry,
  setSelectedCountry,
} from '../features/authoritySelection/actions';
import { getDerivedAuthority } from '../features/authoritySelection/utilities.js';
import { getPOITYPESForAuthority } from '../features/myCity/actions.js';
import { getProfile } from '../features/profile/actions';
import {
  getInitialParams,
  getLanguage,
  getTranslatedLanguageWithUserSettings,
} from '../services/GNRL';
import { translateCountry } from '../services/TranslationService';
import WEBAPI from '../services/WEBAPI.js';
import { setItem } from '../services/localStorageWrap';
import { getAuthorityForCurrentApplication } from '../services/platformCheck';
import * as ATypes from './actionTypes';

export const goToAuthorityOrMainApp = () => {
  return (dispatch, getState) => {
    var userAuthAdded = !!getState().profileReducer.profile.authority;

    //TODO: MINOR Maybe this should be here, logic wise it makes sense
    //as you will always have registered at this point, and it saves on code
    //but architecturally.. revisit
    return setItem('hasEverRegistered', true).then(() => {
      if (userAuthAdded) {
        dispatch(goToMainApp());
      } else {
        dispatch(goToAuthoritySelection());
      }
    });
  };
};

const saveLanguageToLocalStorage = (language) => {
  localStorage.setItem('language', language);
};

export const setLanguage = (language) => {
  return async (dispatch, getState) => {
    dayjs.locale(getTranslatedLanguageWithUserSettings(language));
    saveLanguageToLocalStorage(language);

    dispatch({
      type: ATypes.SET_SELECTED_LANGUAGE,
      payload: language,
    });

    await dispatch(getTranslations());
    await dispatch(getCountries());
    await dispatch(getAllAuthoritiesForCountry());
  };
};

export const setInternetStatus = (hasInternet) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.SET_INTERNET_STATUS,
      payload: hasInternet,
    });
  };
};

export const setDynamicLinksParams = (data) => {
  return (dispatch) => {
    dispatch({
      type: ATypes.SET_DYNAMIC_LINKS_PARAMS,
      payload: data,
    });
  };
};

export const deleteDynamicLinksParams = (paramsToDelete) => {
  return (dispatch, getState) => {
    let state = getState();
    let currentParams = _.get(state, 'settingsReducer.dynamicLinksParams', {});
    let data = _.omit(currentParams, paramsToDelete);
    dispatch({
      type: ATypes.DELETE_DYNAMIC_LINKS_PARAMS,
      payload: data,
    });
  };
};

export const resetDynamicLinksParams = () => {
  return (dispatch) => {
    dispatch({
      type: ATypes.RESET_DYNAMIC_LINKS_PARAMS,
    });
  };
};

export const goToAuthoritySelection = (withData) => {
  return (dispatch, getState) => {
    dispatch(push('/authoritySelection', withData));
  };
};

export const goToAddMobile = (from) => {
  console.log('from', from);
  return (dispatch, getState) => {
    dispatch(push('/addPhone', { state: { from } }));
  };
};

export const goToVerifyEmail = (from) => {
  return (dispatch, getState) => {
    dispatch(push('/verifyEmail', { state: { from } }));
  };
};

export const verifyRequest = (
  mobileOrEmail,
  application,
  captchaKey,
  captchaValueParam
) => {
  return (dispatch) => {
    dispatch({
      type: ATypes.POST_VERIFY_REQ_REQ,
    });

    const language = getLanguage() === 'gr' ? 'el' : getLanguage();
    return WEBAPI.verifyRequest(
      mobileOrEmail,
      application,
      language,
      captchaKey,
      captchaValueParam
    ).then((res) => {
      dispatch({
        type: ATypes.POST_VERIFY_REQ_RES,
      });
      return res;
    });
  };
};

export const postError = (type, err) => {
  return (dispatch, getState) => {
    let state = getState();
    let error = _.get(err, 'details.user_description', 'UNKOWN_ERROR');
    let token = _.get(state, 'authReducer.auth_token');
    let data = { error, type };
    let authorityName =
      getState().profileReducer.profile.authority.name ??
      getAuthorityForCurrentApplication();

    return WEBAPI.postError(data, token, authorityName);
  };
};

export const goToMainApp = () => {
  return (dispatch, getState) => {
    dispatch(push('/home'));
    // dispatch(push("/phonebook"));
  };
};

export const goTo = (page, urlData, reducerData) => {
  return (dispatch, getState) => {
    page = '/' + page;
    if (urlData) {
      page = page + '/' + urlData;
    }

    dispatch(
      push({
        pathname: page,
        state: reducerData,
      })
    );
  };
};

export const replaceRouter = (page, urlData, withData) => {
  return (dispatch, getState) => {
    page = '/' + page;
    if (urlData) {
      page = page + '/' + urlData;
    }

    dispatch(replace(page, withData, { KEKEKE: 'kokoko' }));
  };
};

export const goBackRouter = (withData) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GOBACKDATASTORE',
      payload: withData,
    });
    dispatch(goBack());
  };
};

const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

//BOOTSTRAPING ACTIONS

export const getSystemStatus = (dispatch) => {
  dispatch({
    type: ATypes.GET_SYSTEM_STATUS,
  });
  return WEBAPI.getSystemStatus()
    .then((res) => {
      dispatch({
        type: ATypes.SET_FORGOT_PASS_URL,
        payload: res,
      });
      return res;
    })
    .then((res) => {
      dispatch({
        type: ATypes.SET_SUPPORTED_SOCIAL_NETWORK_AUTH,
        payload: res,
      });
      return res;
    });
};

export const getTranslations = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_TRANSLATIONS_REQUEST,
    });
    let tempLang = getLanguage();
    let language = _.get(
      getState(),
      'settingsReducer.selectedLanguage',
      tempLang
    );
    return WEBAPI.getTranslations(language)
      .then((res) => {
        dispatch({
          type: ATypes.GET_TRANSLATIONS_RESPONSE,
          payload: res,
        });
        return res;
      })
      .catch((error) => {
        // Optionally handle any errors here
        console.error('Failed to fetch translations:', error);
      });
  };
};

export const getCountries = () => {
  return (dispatch) => {
    dispatch({
      type: ATypes.GET_COUNTRIES_REQUEST,
    });

    return WEBAPI.getCountries().then((res) => {
      const countries = Object.keys(res.result)
        .map((key) => res.result[key])
        .map((country) => {
          country.translatedName = translateCountry(country.name);
          return country;
        });

      dispatch({
        type: ATypes.GET_COUNTRIES_RESPONSE,
        payload: countries,
      });
      return countries;
    });
  };
};

export const getModules = () => {
  return (dispatch, getState) => {
    let myAuthName =
      _.get(getState(), 'profileReducer.profile.authority.name') ??
      getAuthorityForCurrentApplication();
    if (!myAuthName) return Promise.resolve();

    dispatch({
      type: ATypes.GET_MODULES_REQ,
    });

    return WEBAPI.getModules(myAuthName)
      .then((res) => {
        dispatch({
          type: ATypes.GET_MODULES_RES,
          payload: res.result,
        });
        return res.result;
      })
      .then((modules) => {
        return modules;
      });
  };
};

export const getAuthorityDetails = () => {
  return (dispatch, getState) => {
    const state = getState();

    let myAuthName = getDerivedAuthority(state);
    if (!myAuthName) return Promise.resolve();

    dispatch({
      type: ATypes.GET_AUTHORITY_DETAILS_REQ,
    });

    return WEBAPI.getAuthorityDetails(myAuthName).then((res) => {
      dispatch({
        type: ATypes.GET_AUTHORITY_DETAILS_RES,
        payload: res.result,
      });
      return res.result;
    });
  };
};

export const initializeLanguage = (language) => ({
  type: 'SET_SELECTED_LANGUAGE',
  payload: language,
});

export const initialProcess = (firebaseDynamicLinkParams) => {
  return (dispatch, getState) => {
    let systemStatusResponse;
    return dispatch(getSystemStatus)
      .then((res) => {
        systemStatusResponse = res;
      })
      .then(() => {
        var paramObj = getInitialParams({ firebaseDynamicLinkParams });
        if (paramObj.displayMessageAuthority) {
          paramObj['setAuthority'] = paramObj.displayMessageAuthority;
          paramObj['setCountry'] = paramObj.displayMessageCountry
            ? paramObj.displayMessageCountry
            : 'el';
          paramObj['goToPage'] = 'home';
        }
        return dispatch(setOriginalUrlParameters(paramObj));
      })
      .then(() => {
        if (!_.isEmpty(firebaseDynamicLinkParams)) {
          return dispatch(setDynamicLinksParams(firebaseDynamicLinkParams));
        }
      })
      .then(() => {
        const { translations } = getState()?.translationsReducer;
        const { countries } = getState()?.countriesReducer;

        if (
          translations &&
          Object.keys(translations).length !== 0 &&
          countries &&
          countries?.length !== 0
        ) {
          dispatch(getTranslations);
          dispatch(getCountries);
          return Promise.resolve();
        } else {
          return dispatch(getTranslations()).then(() =>
            dispatch(getCountries())
          );
        }
      })
      .then(() => {
        if (getState().authReducer.auth_token) {
          return dispatch(getProfile()).then((res) => {
            return dispatch(getAuthorityDetails()).then(() => {
              return dispatch(getModules());
            });
          });
        }
      })
      .then(() => {
        return dispatch(setSelectedCountry());
      })
      .then(() => {
        const isMyCityModuleEnabled = getState()?.otherReducer?.modules.find(
          (item) => item.name === 'mycity'
        );
        const authority = getState()?.profileReducer?.profile?.authority?.name;
        if (isMyCityModuleEnabled && authority) {
          return Promise.all([
            dispatch(getPlacemarks()),
            dispatch(getPOITYPESForAuthority()),
          ]);
        }
      })
      .then(() => {
        return systemStatusResponse;
      });
  };
};

export const setOriginalUrlParameters = (originalUrlParams) => {
  return (dispatch, getState) => {
    return dispatch({
      type: ATypes.SET_ORIGINAL_URL_PARAMS,
      payload: originalUrlParams,
    });
  };
};

export const unsetOriginalUrlParameters = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: ATypes.UNSET_ORIGINAL_URL_PARAMS,
    });
  };
};

export const refreshToken = () => {
  return (dispatch, getState) => {
    return WEBAPI.refreshToken().then((res) => {
      dispatch({
        type: ATypes.REFRESH_TOKEN_RES,
        payload: res.result,
      });
    });
  };
};

export const getGeozones = (authority) => {
  return (dispatch, getState) => {
    if (!authority) {
      authority =
        getAuthorityForCurrentApplication() ||
        getState().profileReducer.profile.authority.name;
    }

    dispatch({
      type: ATypes.GET_GEOZONES_REQ,
    });

    return WEBAPI.getGeozones(authority).then((res) => {
      dispatch({
        type: ATypes.GET_GEOZONES_RES,
        payload: res.result,
      });
    });
  };
};

export const getPlacemarks = ({ authority } = {}) => {
  const PLACEMARK_TYPES = {
    MY_CITY_BOUNDS: 'municipal_district',
    PARKING_AREA: 'parking_area',
    PARKING_ZONE: 'parking_zone', //these are the sensor data
  };

  return (dispatch, getState) => {
    let hasParkingModule = getState().otherReducer?.modules?.some(
      (item) => item.name === 'parking'
    );
    let hasMyCityModule = getState().otherReducer?.modules?.some(
      (item) => item.name === 'mycity'
    );

    if (!authority) {
      authority =
        getAuthorityForCurrentApplication() ||
        getState()?.profileReducer?.profile?.authority?.name;
    }

    //Request my all parking related placemarks to draw the parking bounds and availability if any
    if (hasParkingModule) {
      dispatch({
        type: ATypes.GET_PLACEMARKS_REQ,
        info: 'parking_placemarks',
      });

      WEBAPI.getPlacemarks({
        authority,
        typesArray: [
          PLACEMARK_TYPES.PARKING_AREA,
          PLACEMARK_TYPES.PARKING_ZONE,
        ],
      }).then((res) => {
        let processedPlacemarkData = {
          parking_area: {
            type: 'FeatureCollection',
            features: [],
          },
          parking_zone: {
            type: 'FeatureCollection',
            features: [],
          },
        };

        //This should ideally be server side as we're now assuming that
        //polygons are for the parking zone and that LineStrings are for the
        //availability data that is based on sensors
        res.result.features.forEach((feature) => {
          if (feature.properties?.type === PLACEMARK_TYPES.PARKING_ZONE) {
            processedPlacemarkData['parking_zone'].features.push(feature);
          } else if (
            feature.properties?.type === PLACEMARK_TYPES.PARKING_AREA
          ) {
            processedPlacemarkData['parking_area'].features.push(feature);
          }
        });

        dispatch({
          type: ATypes.GET_PLACEMARKS_RES,
          payload: processedPlacemarkData,
          info: 'parking_placemarks',
        });
      });
    }

    //Request my city placemarks to draw the bounds in my city if any
    if (hasMyCityModule) {
      dispatch({
        type: ATypes.GET_PLACEMARKS_REQ,
        info: 'city_placemarks',
      });

      WEBAPI.getPlacemarks({
        authority,
        typesArray: [PLACEMARK_TYPES.MY_CITY_BOUNDS],
      }).then((res) => {
        dispatch({
          type: ATypes.GET_PLACEMARKS_RES,
          payload: res.result,
          info: 'city_placemarks',
        });
      });
    }
  };
};

export const getPermissions = (authority) => {
  return (dispatch, getState) => {
    if (!authority) {
      authority =
        getAuthorityForCurrentApplication() ||
        getState().profileReducer.profile.authority.name;
    }
    dispatch({
      type: ATypes.GET_PERMISSIONS_REQ,
    });

    return WEBAPI.getPermissions(authority).then((res) => {
      dispatch({
        type: ATypes.GET_PERMISSIONS_RES,
        payload: res.result,
      });

      return res.result;
    });
  };
};
