import { getLanguage } from '@/services/GNRL';
import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';
import { getDerivedAuthority } from '../authoritySelection/utilities';

export const getApplicationsTypes = () => {
  return async (dispatch, getState) => {
    const store = getState();
    const authorityName = getDerivedAuthority(store);

    const language = getLanguage();

    dispatch({
      type: ATypes.GET_APPLICATIONS_TYPE_REQ,
    });

    try {
      const res = await WEBAPI.getApplicationTypes(authorityName, language);

      dispatch({
        type: ATypes.GET_APPLICATIONS_TYPE_RES,
        payload: res.result,
      });
    } catch (err) {
      dispatch({
        type: ATypes.GET_APPLICATIONS_TYPE_FAIL,
        payload: err,
      });
    }
  };
};

export const getSubmittedApplications = () => {
  return async (dispatch, getState) => {
    const store = getState();
    const authorityName = getDerivedAuthority(store);

    const language = getLanguage();

    dispatch({
      type: ATypes.GET_SUBMITTED_APPLICATIONS_REQ,
    });

    try {
      const res = await WEBAPI.getApplicationSubmissionsRequest(
        authorityName,
        language
      );

      dispatch({
        type: ATypes.GET_SUBMITTED_APPLICATIONS_RES,
        payload: res.result,
      });
    } catch (err) {
      dispatch({
        type: ATypes.GET_SUBMITTED_APPLICATIONS_FAIL,
        payload: err,
      });
    }
  };
};

export const setActiveApplication = (application) => {
  return {
    type: ATypes.SET_ACTIVE_APPLICATION,
    payload: application,
  };
};
