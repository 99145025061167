import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Calendar, List, Megaphone, MessageCircleWarning } from 'lucide-react';
import { Loader2 } from 'lucide-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NovovilleButton } from '@/components/novoville';
import { goTo } from '../../shared/actions';
import { withTranslation } from '../../shared/components/withTranslation';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import MessageItem from './MessageItem';
import NoMessages from './NoMessages';
import { getMessages, getMoreMessages } from './actions';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      scrollPosition: 0,
      activeTab: props.location?.state?.tab || 'ALL',
    };
    this.props.setHeaderTitle(this.props.translate('sideMenu.Messages'));
  }

  componentDidMount() {
    const { getMessages } = this.props;
    getMessages().then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.state?.tab !== this.props.location?.state?.tab) {
      this.setState({ activeTab: this.props.location?.state?.tab || 'ALL' });
    }
  }

  getYPosition = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  getMoreMessages = () => {
    const { nextUrl, getMoreMessages } = this.props;
    this.setState({ loading: true, scrollPosition: this.getYPosition() });
    getMoreMessages(nextUrl)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  filterMessages = () => {
    const { messages } = this.props;
    const { activeTab } = this.state;

    if (activeTab === 'ALL') {
      return messages;
    }

    return messages.filter((message) => message.app_type === activeTab);
  };

  getTabs = () => {
    const { translate } = this.props;
    return [
      { name: translate('All'), icon: Megaphone, type: 'ALL' },
      {
        name: translate('Announcements'),
        icon: MessageCircleWarning,
        type: 'ANNOUNCEMENT',
      },
      { name: translate('Events'), icon: Calendar, type: 'EVENT' },
      { name: translate('Polls'), icon: List, type: 'POLL' },
    ];
  };

  render() {
    const { goTo, translate, nextUrl } = this.props;
    const { loading, activeTab } = this.state;
    const filteredMessages = this.filterMessages();
    const tabs = this.getTabs();

    const noMessages = filteredMessages.length === 0;

    return (
      <div className="flex flex-col h-[calc(100vh-80px)] max-w-[100vw]">
        <div className="flex flex-col gap-[16px] overflow-auto p-0 sm:gap-[24px] sm:p-[24px] flex-grow">
          <div className="flex max-w-screen">
            <TabNavigation
              activeTab={activeTab}
              setActiveTab={(tab) => this.setState({ activeTab: tab })}
              tabs={tabs}
            />
          </div>
          <div className="flex flex-col bg-white sm:rounded-[16px] rounded-0 flex-grow overflow-y-auto">
            <div className="flex flex-col gap-4 flex-grow">
              {noMessages ? (
                <div className="pt-[24px]">
                  <NoMessages type={activeTab} />
                </div>
              ) : (
                <MessagesList messages={filteredMessages} goTo={goTo} />
              )}
              {nextUrl && (
                <NovovilleButton
                  className="w-fit self-center my-4"
                  variant="secondary"
                  onClick={this.getMoreMessages.bind(this)}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                  ) : null}
                  {loading ? translate('Loading') : translate('LoadMore')}
                </NovovilleButton>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TabNavigation = ({ activeTab, setActiveTab, tabs }) => (
  <div className="flex w-fit overflow-x-auto gap-2 sm:rounded-lg rounded-0 bg-white p-2">
    {tabs.map((tab) => (
      <button
        key={tab.type}
        onClick={() => setActiveTab(tab.type)}
        className={`flex items-center space-x-2 rounded-lg px-4 py-2 transition-colors ${
          activeTab === tab.type
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-500 hover:bg-gray-50'
        }`}
      >
        <tab.icon className="h-5 w-5" />
        <span>{tab.name}</span>
      </button>
    ))}
  </div>
);

const MessagesList = (props) => {
  const { messages, goTo } = props;
  return messages.map((message) => (
    <MessageItem listView={true} key={message.id} message={message} goTo={goTo} />
  ));
};

const mapStateToProps = (state) => ({
  messages: state.messagesReducer.messages,
  nextUrl: state.messagesReducer.nextUrl,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMessages,
      getMoreMessages,
      goTo,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation(withNOVOHeader(Messages)))
);
